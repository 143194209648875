import { createDirectus, staticToken, rest, readTranslations, readItem, readItems, readField, updateItem, createItem, deleteItem, uploadFiles, updateFile, utilitySort} from '@directus/sdk';
import { EventEmitter } from 'events';

class SaasApp extends EventEmitter {
  constructor() {
    super();

    console.log('Initializing Directus SDK');

    this.directus_url = 'https://phpstack-791938-4485405.cloudwaysapps.com/';
    this.directus_assets_url = this.directus_url + 'assets/';

    this.authenticated = false;

    this.directus = createDirectus(this.directus_url).with(staticToken('94sb6x9zFG9hVA9G2AXzqlkNxGXSDGQk')).with(rest());

    this._guilanguage = 'nl-NL';
    this._fields = [];
    this._translations = {};

    this.authenticateWithStaticToken();
    this.getTranslations();
  }

  set guilanguage(value) {
    this._guilanguage = value;
    this._emitChangeLanguage();
  }

  get guilanguage() {
    return this._guilanguage;
  }

  set translations(value) {
    this._translations = value;
  }

  get translations() {
    return this._translations;
  }

  set fields(value) {
    this._fields = value;
    this._emitChangeProduct();
  }

  get fields() {
    return this._fields;
  }

  async getCurrentUser() {
    await this.directus.users.me.read();
  }

  async authenticateWithStaticToken() {
      this.authenticated = true;
  }

  // TRANSLATIONS
  async getTranslations() {

    const result = await this.directus.request(readTranslations({}))
    .catch((error) => {
      console.log(error);
    });

    const translations = {};
    for (let i = 0; i < result.length; i++) {
      const translation = result[i];
      if (!Object.hasOwnProperty.call(translations, translation.language)) {
        translations[translation.language] = {};
      }
      translations[translation.language][translation.key] = translation.value;
    }

    this.translations = translations;

  }

  getTranslationValue(key, default_value) {
    if (!Object.hasOwnProperty.call(this.translations, this.guilanguage)) {
      return default_value;
    }
    if (!Object.hasOwnProperty.call(this.translations[this.guilanguage], key)) {
      return default_value;
    }
    return this.translations[this.guilanguage][key];
  }

  getLanguage() {
    return this.guilanguage;
  }

  setLanguage(key) {
    this.guilanguage = key;
  }

  //FILES
  async createFile(form_data) {
    const result = await this.directus.request(uploadFiles(form_data)).catch((error) => {
      console.log(error);
      alert('File not uploaded');
    });
    return result;
  }

  updateItemFile(file_id, form_data) {
    return this.directus.request(updateFile(file_id, form_data)).catch((error) => {
      console.log(error);
      alert('File not uploaded');
    });
  }

  //SINGLE ITEM
  getItem (collection, id, fields = ['*']) {
    return this.directus.request(readItem(collection, id, {
      fields: fields
    }))
      .catch((error) => {
        console.log(error);
      });
  }

  //COLLECTIONS
  async getCollectionCount(collection) {
    const result = await this.directus.request(readItems(collection, {
      filter: { status: { _neq: 'archived' } },
      aggregate: { countDistinct: 'id' }
    }))
    .catch((error) => {
      console.log(error);
    });
    return result[0].countDistinct;
  }

  getCollection(collection_name, page = 0, limit = -1, sort = ['sort'], fields = ['*'], filter = {}) {
    return this.directus.request(readItems(collection_name, {
      limit: limit,
      page: page,
      sort: sort,
      fields: fields,
      filter: filter
    }))
    .catch((error) => {
      console.log(error);
    });
  }

  async createItem(collection_name, item_data) {
    const item = await this.directus.request(createItem(collection_name, item_data))
      .catch((error) => {
        console.log(error);
    });
    return item;
  }

  updateItemField(collection_name, id, field, value, query = {}) {
    return this.directus.request(updateItem(collection_name, id, {
      [field]: value,
    }, query))
    .catch((error) => {
      console.log(error);
    });
  }

  deleteItem(collection_name, id) {
    return this.directus.request(deleteItem(collection_name, id))
    .catch((error) => {
      console.log(error);
    });
  }

  sortItem(collection_name, from_id, too_id) {
    return this.directus.request(utilitySort(collection_name, from_id, too_id))
    .catch((error) => {
      console.log(error);
    });
  }

  getImage(image, cache = true) {
    if (image == null || image == undefined || image == '') {
      return '';
    }

    const id = image.id;
    if (id == null || id == undefined || id == '') {
      return '';
    }
    //const result = await this.directus.request(readAssetRaw(id, {key: 'thumb64'})); voor auth
    if(cache){
      return this.directus_assets_url + id + '/image.png?&ts=' + Date.now();
    }
    return this.directus_assets_url + id + '';
  }


  getImage2(id, cache = true) {
    if (id == null || id == undefined || id == '') {
      return '';
    }
    //const result = await this.directus.request(readAssetRaw(id, {key: 'thumb64'})); voor auth
    if(cache){
      return this.directus_assets_url + id + '/thumbnail.png?ts=' + Date.now();
    }
    return this.directus_assets_url + id + '/thumbnail.png';
  }

  getThumbnail(id, cache = true) {
    if (id == null || id == undefined || id == '') {
      return '';
    }
    //const result = await this.directus.request(readAssetRaw(id, {key: 'thumb64'})); voor auth
    if(cache){
      return this.directus_assets_url + id + '/thumbnail.png?key=thumb64&ts=' + Date.now();
    }
    return this.directus_assets_url + id + '/thumbnail.png?key=thumb64';
  }

  //ROUTING
  goToo(location) {
    switch (location) {
      case '/':
        this.goToHome();
        break;
      default:
        window.location.href = location;
        break;
    }
  }

  goBack(location) {
    switch (location) {
      case '/':
        this.goToHome();
        break;
      default:
        window.location.href = location;
        break;
    }
  }

  goToHome() {
    window.location.href = '/';
  }

  createHexColorImage(hex_color) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.height = 64;
    canvas.width = 64;
    ctx.fillStyle = hex_color;
    ctx.fillRect(0, 0, 64, 64);
    return canvas.toDataURL();
  }

  //create random uuid
  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // eslint-disable-next-line no-mixed-operators
      const r = (Math.random() * 16) | 0;
      // eslint-disable-next-line no-mixed-operators,eqeqeq
      const v = c == 'x' ? r : (r & 0x3) | 0x8;
      // eslint-disable-next-line eqeqeq
      return v.toString(16);
    });
  }

  //https://codepen.io/dulldrums/pen/RqVrRr
  toHex(buffer) {
    let hexCodes = [];
    let view = new DataView(buffer);
    for (let i = 0; i < view.byteLength; i += 4) {
      let value = view.getUint32(i);
      let stringValue = value.toString(16);
      let padding = '00000000';
      let paddedValue = (padding + stringValue).slice(-padding.length);
      hexCodes.push(paddedValue);
    }
    return hexCodes.join('');
  }

}

export default new SaasApp();
