
import saasApp from "../../routes/app.js";

<div>  
    <div>
        <${input.renderBody}/>
    </div>
</div>

class {
    onCreate() {this.state = {};}
    onInput(input) {}
    onMount() {}
}